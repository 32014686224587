<template> 
<div class="row p-0 m-0">
    <div class="col-12 p-0 m-0 mt-4 px-3 d-flex justify-content-between">
      <div class="organization-title">
          Edit Company Details
      </div>
      <div class=" back-button" @click="backToProfile('organization')">
          <img src="../../assets/SVG/Back.svg" width="60px">
      </div>
  </div>
      <div class="col-12 p-0 m-0 d-block">
          <div class="organization-form-wrapper">
              <div class="row p-0 m-0">
                  <div class="col-lg-4 col-md-4 col-12 m-0">
                    <w-text-input 
                        :labelName="'Company Name'" 
                        :newInputStyle="'profile-input'" 
                        :defaultValue="companyDetails.companyName"
                        @textInputChange="handleFormInput($event, 'companyName')"
                        :fieldName="'Company Name'"
                    />
                  </div>
                  <div class="col-lg-4 col-md-4 col-12 m-0">
                    <w-text-input 
                        :labelName="'GSTIN'" 
                        :newInputStyle="'profile-input'"
                        :defaultValue="companyDetails.gstin"
                        @textInputChange="handleFormInput($event, 'gstin')"
                        :fieldName="'GSTIN'"
                    />
                  </div>
                  <div class="col-lg-4 col-md-4 col-12 m-0">
                    <w-text-input 
                        :labelName="'Contact Number'" 
                        :newInputStyle="'profile-input'"
                        :defaultValue="companyDetails.phoneNumber"
                        @textInputChange="handleFormInput($event, 'contactNumber')"
                        :fieldName="'Contact Number'"
                    />
                  </div>
                  <div class="col-lg-4 col-md-4 col-12 m-0">
                    <w-text-input 
                        :labelName="'Alternate Contact Number'" 
                        :newInputStyle="'profile-input'" 
                        :defaultValue="companyDetails.alternatePhoneNumber"
                        @textInputChange="handleFormInput($event, 'alternatePhoneNumber')"
                        :fieldName="'Alternate Contact Number'"
                    />
                  </div>
                  <div class="col-lg-4 col-md-4 col-12 m-0">
                    <w-text-input 
                        :labelName="'Email'"  
                        :newInputStyle="'profile-input'" 
                        :defaultValue="companyDetails.email"
                        @textInputChange="handleFormInput($event, 'email')"
                        :fieldName="'email'"
                    />
                  </div>
                  <!-- <div class="col-lg-4 col-md-4 col-12 m-0">
                      <label class="logo-label">Company Logo</label>
                      <span class="ml-5" style="font-size:12px; color:red" v-if="isFilesize">*File size must be less than 3MB</span>
                      <div class="user-icon p-0">
                          <div class="upload-logo">
                              <img src="../../assets/Profile/uploadPicture.svg"> 
                              <p>Click to change logo</p>
                              <input type="file" id="company-logo" accept="image/*" class="upload-file-input" @change="onFileSelected">
                          </div>
                            <img :src="companyLogoUrl" width="80px" height="80px" style="border-radius:50%" />
                      </div>
                  </div> -->
                  <div class="col-12 m-0 mr-auto">
                      <div class="col-lg-4 col-md-4 col-12 p-0 m-0">
                        <w-text-input 
                            :labelName="'Pincode'" 
                            :newInputStyle="'profile-input'"
                            :defaultValue="companyDetails.address.pinCode"
                            @textInputChange="handleFormInput($event, 'pincode')"
                            :fieldName="'pincode'"
                        />
                      </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12 m-0">
                      <w-text-input 
                        :labelName="'Address Line 1'" 
                        :newInputStyle="'profile-input'"
                        :defaultValue="companyDetails.address.addressLine1"  
                        @textInputChange="handleFormInput($event, 'address1')"
                        :fieldName="'address'"
                      />
                  </div>
                  <div class="col-lg-6 col-md-6 col-12 m-0">
                    <w-text-input 
                        :labelName="'Address Line 2'" 
                        :newInputStyle="'profile-input'"
                        :defaultValue="companyDetails.address.addressLine2"
                        @textInputChange="handleFormInput($event, 'address2')"
                        :fieldName="'address'"
                    />
                  </div>
                  <div class="col-lg-4 col-md-4 col-12 m-0">
                    <w-text-input 
                        :labelName="'City'"
                        :newInputStyle="'profile-input'" 
                        :defaultValue="companyDetails.address.city"
                        @textInputChange="handleFormInput($event, 'city')"
                        :fieldName="'city'"
                    />
                  </div>
                  <div class="col-lg-4 col-md-4 col-12 m-0">
                    <w-text-input 
                        :labelName="'State'" 
                        :newInputStyle="'profile-input'"
                        :defaultValue="companyDetails.address.state"  
                        @textInputChange="handleFormInput($event, 'state')"
                        :fieldName="'state'"
                    />
                  </div>
                  <div class="col-lg-4 col-md-4 col-12 m-0">
                    <w-text-input 
                        :labelName="'Country'" 
                        :newInputStyle="'profile-input'" 
                        :defaultValue="companyDetails.address.country" 
                        @textInputChange="handleFormInput($event, 'country')"
                        :fieldName="'Country'"
                    />
                  </div>
              </div>
              <div class="row p-0 m-0 text-center justify-content-center">
                  <div class="col-4 p-0 m-0">
                      <w-button-input :label="'Save'" :buttonStyle="'generate-btn'" :buttonClass="'generate-btn'" class="mt-3" @buttonClicked="saveCompanyDetails()" />
                  </div>
                  <div class="col-4 p-0 m-0">
                      <w-button-input :label="'Cancel'" :buttonStyle="'request-demo-style'" class="ml-3 mt-3" :buttonClass="'cancel-button'" @buttonClicked="backToProfile('organization')" />
                  </div>
              </div>
          </div>
      </div>
</div >
  
</template>

<script>
import TextInput from "../../widgets/TextInput.vue";
import ButtonInput from "../../widgets/InputButton.vue";
// import { uploadCompanyLogo } from "../../Service/UserService.js";

export default {
    props:["companyDetails"],
    components:{
        "w-text-input": TextInput,
        "w-button-input": ButtonInput
    },
    data(){
        return{
            isUploadLogo:false,
            // isFilesize:false,
        }
    },
    methods:{
        handleFormInput(event,inputType){
            switch(inputType){
                case 'companyName':
                    this.companyDetails.companyName = event;
                    return;
                case 'gstin':
                    this.companyDetails.gstin = event;
                    return;
                case 'contactNumber':
                    this.companyDetails.phoneNumber = event;
                case 'alternatePhoneNumber':
                    this.companyDetails.alternatePhoneNumber = event;
                    return;
                case 'email':
                    this.companyDetails.email = event;
                    return;
                case 'pincode':
                    this.companyDetails.address.pincode = event;
                    return;
                case 'address1':
                    this.companyDetails.address.addressLine1 = event;
                    return;
                case 'address2':
                    this.companyDetails.address.addressLine2 = event;
                    return;
                case 'city':
                    this.companyDetails.address.city = event;
                    return;
                case 'state':
                    this.companyDetails.address.state = event;
                    return;
                case 'country':
                    this.companyDetails.address.country = event;
                    return;
                default:
                    return;
            }
        },

        backToProfile(type){
            this.$emit("backToProfile",type);
        },

        // select company logo on onChange
        // onFileSelected(event){
        //     if(event.target.files[0].size < 3000000){
        //         this.companyLogoUrl= event.target.files[0];
        //         this.isFilesize= false;
        //     }else{
        //         this.isFilesize= true;
        //     }
        // },

        // submitCompanyLOGO(){
        //   if(this.companyLogoUrl){
        //     const formData = new FormData();
        //     formData.append('file', this.companyLogoUrl)

        //     uploadCompanyLogo(formData)
        //     .then((res) => {
        //         console.log("upload filee", res)
        //     })
        //   }
        // },
        // update company form
        saveCompanyDetails(){
            // this.submitCompanyLOGO();
            this.$emit("updateCompanyEvent",this.companyDetails)
        }
    }
}
</script>

<style scoped>
    .organization-title{
        color:#4A4A4A;
        font-size: 16px;
        font-weight: bold;
    }   
    .back-button{
        text-align: right;
        color:#2E8CD3;
        font-size: 14px;
        cursor: pointer;
        float: right;
    } 
    .organization-form-wrapper{
        background-color: #FFFFFF;
        padding: 1rem;
        margin:1rem;
        border-radius: 5px;
        box-shadow: 0px 1px 10px #4B4B4B1A;
    }
    /* .logo-label{
        color: #767676;
        font-size: 12px;
        font-weight: normal;
    } */
    /* .user-icon{
        background-color: #7d9ffc;
        padding: 10px;
        border-radius: 50%;
        width: 75px;
        height: 75px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 800;
        color: #FFFFFF;
        cursor: pointer;
        align-items: center;
        text-align: center;
        display: grid;
        overflow: hidden;
    } */
    /* .upload-file-input{
        display: block;
        width: 75px;
        height: 75px;
        background-color: red;
        border-radius: 50%;
        top:0;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    } */
    /* .user-icon:hover .upload-logo{
        display: block;
    } */
    /* .upload-logo{
            display: none;
            width: 75px;
            height: 75px;
            border-radius: 100%;
            left: 15px;
            top: 41px;
            position: absolute;
            animation-name: showUpload ;
            animation-duration: .1s;
            background-color: #00000050;
            font-size: 7px;
            align-items: center;
            text-align: center;
            padding: 10px;
    }
    .upload-logo > img{
        width: 20px;
        margin: 5px 0px;
    } */
    /* @keyframes showUpload {
        0%{
            width: 25px;
            height: 25px;
            font-size: 0px;
            left: 20px;
            top: 45px;
        }
        100%{
            width: 75px;
            height: 75px;
            font-size: 7px;
            left: 15px;
            top: 41px;
        }
    } */
</style>