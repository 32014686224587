<template>
  <div>
    <div class="row m-0 profile-card-wrapper">
      <div
        class="col-lg-6 col-md-6 col-12"
        v-if="!isUserForm && !isOrganizationForm"
      >
        <div class="profile-card">
          <div>
            <span class="ml-5" style="font-size:12px; color:red" v-if="isCompanyFilesize">*File size must be less than 3MB</span>
            <div class="user-icon p-0">
                <div class="upload-logo">
                    <img src="../assets/Profile/uploadPicture.svg"> 
                    <p>Click to change logo</p>
                    <input type="file" id="company-logo" accept="image/*" class="upload-file-input" @change="onCompanyLogoSelected">
                </div>
              <avatar v-if="companyDetail && companyLogoUrl" :username="companyDetail.companyName" size="80" :src="validateImageUrl(companyLogoUrl)"></avatar>
            </div>
          </div>
          <p class="profile-card-title">
            {{ companyDetail.companyName }}
            <img
              src="../assets/Profile/profileEdit.svg"
              style="cursor: pointer;"
              class="ml-2"
              @click="showForm('organization')"
            />
          </p>
          <p class="gstin">GSTIN: {{ companyDetail.gstin }}</p>
          <hr class="horizontal-line" />
          <div class="d-flex about-section">
            <img
              class="about-icon-wrapper"
              src="../assets/Profile/location.svg"
            />
            <span class="ml-3" v-if="companyDetail.address">
              {{ companyDetail.address.addressLine1 }}
              {{ companyDetail.address.addressLine2 }},
              {{ companyDetail.address.city }},
              {{ companyDetail.address.state }}
              {{ companyDetail.address.country }} -
              {{ companyDetail.address.pinCode }}</span
            >
          </div>
          <div class="d-flex about-section">
            <img class="about-icon-wrapper" src="../assets/Profile/phone.svg" />
            <div class="d-block">
              <p class="ml-3 mb-2">{{ companyDetail.phoneNumber }}</p>
              <!-- <p class="ml-3 m-0">+91-9876541230</p> -->
            </div>
          </div>
          <div class="d-flex about-section">
            <img class="about-icon-wrapper" src="../assets/Profile/mail.svg" />
            <span class="ml-3">{{ companyDetail.email }}</span>
          </div>
        </div>
      </div>
      <div
        class="col-lg-6 col-md-6 col-12"
        v-if="!isUserForm && !isOrganizationForm"
      >
        <div class="profile-card">
          <div>
            <span class="ml-5" style="font-size:12px; color:red" v-if="isUserFilesize">*File size must be less than 3MB</span>
            <div class="user-icon p-0">
                <div class="upload-logo">
                    <img src="../assets/Profile/uploadPicture.svg"> 
                    <p>Click to change image</p>
                    <input type="file" id="company-logo" accept="image/*" class="upload-file-input" @change="onUserLogoSelected">
                </div>
              <avatar v-if="userDetails && profileLogoUrl" :username="userDetails.firstName+' '+userDetails.lastName" size="80" :src="validateImageUrl(profileLogoUrl)"></avatar>
            </div>
          </div>

          <p class="profile-card-title">
            {{ userDetails.firstName }} {{ userDetails.lastName }}
            <img
              src="../assets/Profile/profileEdit.svg"
              class="ml-2"
              style="cursor: pointer;"
              @click="showForm('user')"
            />
          </p>
          <p class="gstin text-uppercase">
            <span v-for="(item, index) in userDetails.roles" :key="index" style="text-transform: capitalize">
              {{ item.roleDisplayName }}<span v-if="index < userDetails.roles.length-1">,</span>
            </span>
          </p>
          <hr class="horizontal-line" />
          <!-- <div class="d-flex about-section">
                    <img class="about-icon-wrapper" src="../assets/Profile/location.svg">
                    <span class="ml-3">2nd Floor, VMR Complex, #306, Above Axis Bank, Varthur Road, BEML Layout, Brookefield, Bengaluru, Karnataka, India - 560066</span>
                </div> -->
          <div class="d-flex about-section">
            <img class="about-icon-wrapper" src="../assets/Profile/phone.svg" />
            <div class="d-block">
              <p class="ml-3 mb-2">{{ userDetails.mobileNumber }}</p>
              <!-- <p class="ml-3 m-0">+91-9876541230</p> -->
            </div>
          </div>
          <div class="d-flex about-section">
            <img class="about-icon-wrapper" src="../assets/Profile/mail.svg" />
            <span class="ml-3">{{ userDetails.email }}</span>
          </div>
        </div>
      </div>
      <edit-organization
        v-if="!isUserForm && isOrganizationForm"
        @backToProfile="showForm($event)"
        :companyDetails="companyDetail"
        @updateCompanyEvent="showConfirmModal($event, 'company')"
      />
      <edit-user
        v-if="isUserForm && !isOrganizationForm"
        @backToProfile="showForm($event)"
        :userDetails="userDetails"
        @updateUserEvent="showConfirmModal($event, 'user')"
      />
    </div>

    <!-- error handling modal -->
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />

  </div>
</template>

<script>
import Avatar from 'vue-avatar'
import EditOrganization from "../components/Profile/EditOrganization.vue";
import EditUser from "../components/Profile/EditUser.vue";
import Modal from "../widgets/ModalWidget.vue";
import {
  getCompanyDetails,
  updateCompanyDetails,
  getUserDetails,
  updateUserDetail,
  getCompanyLogo,
  getProfileLogo,
  uplopadUserImage,
  uploadCompanyLogo
} from "../Service/UserService.js";

export default {
  components: {
    Avatar,
    EditOrganization,
    EditUser,
    Modal,
  },
  created() {
    this.getCompanyDetails();
    this.getUserDetails();
    this.getCompanyLogo();
    this.getProfileLogo();
  },
  data() {
    return {
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,

      isOrganizationForm: false,
      isUserForm: false,
      companyDetail: {},
      userDetails: {},
      // userLogo:"",
      companyLogoUrl: "",
      profileLogoUrl: "",
      isUserFilesize:false,
      isCompanyFilesize:false,
    };
  },
  methods: {
    // get company logo
    getCompanyLogo() {
      getCompanyLogo().then((res) => {
        this.companyLogoUrl = res;
      })
      .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // get profile logo
    getProfileLogo() {
      getProfileLogo().then((res) => {
        this.profileLogoUrl = res;
      })
      .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getCompanyDetails() {
      getCompanyDetails().then((res) => {
        this.companyDetail = res;
        // if (this.companyDetail.logoUrl === null) {
        //   let logo = this.companyDetail.companyName.split(" ");
        //   this.comapnyLogo = `${logo[0][0]}${logo[1] ? logo[1][0] : ""}`;
        // }
      })
      .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getUserDetails() {
      getUserDetails(sessionStorage.getItem("userId")).then((res) => {
        this.userDetails = res;
        // this.userLogo = this.userDetails.firstName[0]+this.userDetails.lastName[0]
      })
      .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    backtoOrganisation(){
        this.isOrganizationForm= false;
        this.isUserForm= false;
    },

    sendCompanyDataForUpdate(event) {
      updateCompanyDetails(event).then((res) => {
        this.backtoOrganisation();
      })
      .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // user update
    sendReqForUserUpdate(updateData){
        updateUserDetail(updateData)
        .then((res) => {
          this.backtoOrganisation();
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    confirmOk() {
        if(this.updateType === 'user'){
            this.sendReqForUserUpdate(this.updateData);
        }else{
            this.sendCompanyDataForUpdate(this.updateData);
        }
        
    },

    showConfirmModal(event, type) {
        this.updateType=type;
        this.alert = true;
        this.alertType = "";
        this.isConfirmAlert = true;

        if(this.updateType === "user"){
            this.updateData= event;
            this.alertMsg = `Are you sure want to update the user ?`;
        }else{
            this.updateData= event;
            this.alertMsg = `Are you sure want to update the company ?`;
        }
    },

    // select user logo on onChange
    onUserLogoSelected(event){
      if(event.target.files[0].size < 3000000){
        this.profileLogoUrl= event.target.files[0];
        this.isUserFilesize= false;

        const formData = new FormData();
        formData.append('file', this.profileLogoUrl)

        uplopadUserImage(formData)
        .then((res) => {
          console.log("upload userlogo", res)
          this.getProfileLogo();
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
      }else{
          this.isUserFilesize= true;
      }
    },

    // select company logo on onchange
    onCompanyLogoSelected(event){
      if(event.target.files[0].size < 3000000){
        this.companyLogoUrl= event.target.files[0];
        this.isCompanyFilesize= false;

        const formData = new FormData();
        formData.append('file', this.companyLogoUrl)

        uploadCompanyLogo(formData)
        .then((res) => {
          console.log("upload companyLogoUrl", res)
          this.getCompanyLogo();
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
      }else{
        this.isCompanyFilesize= true;
      }
    },

    showForm(type) {
      if (type === "user") {
        this.isUserForm = !this.isUserForm;
        this.isOrganizationForm = false;
      } else {
        this.isOrganizationForm = !this.isOrganizationForm;
        this.isUserForm = false;
      }
    },

    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },

    validateImageUrl(logoUrl) {
      // TODO: Need to move this function to a common utils file.
      let subStrings = ['.jpeg', '.jpg', '.png', '.gif', '.ico'];
      if (subStrings.some(v => logoUrl.includes(v))) {
        return logoUrl;
      }
      return '';
    }
    
  },
};
</script>

<style scoped>
.profile-card-wrapper {
  padding-left: 3rem;
  padding-right: 2.5rem;
}
.profile-card-wrapper .profile-card {
  background-color: #ffffff;
  padding: 3rem 2rem;
  box-shadow: 0px 1px 10px #4b4b4b1a;
  border-radius: 5px;
  align-items: center;
  text-align: center;
  height: 100%;
  margin: auto;
}
.profile-card-title {
  font-size: 24px;
  color: #1b1c1d;
  font-weight: 600;
  margin: 0.5rem 0rem;
}
.gstin {
  font-size: 14px;
  color: #55595d;
}
.user-icon {
  background-color: #192136;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 800;
  color: #ffffff;
  cursor: pointer;
  margin: auto;
  align-items: center;
  text-align: center;
  display: grid;
}
.horizontal-line {
  margin: 4rem 10rem;
}
.about-icon-wrapper {
  box-shadow: 0px 6px 10px #39393929;
  padding: 0.5rem;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin: auto 0;
}
.about-section {
  font-size: 14px;
  font-weight: normal;
  color: #55595d;
  /* text-transform: capitalize; */
  margin: 3rem 4rem;
  text-align: left;
  /* justify-content: space-between; */
  align-items: center;
}

/* profile logo */
    .upload-file-input{
        display: block;
        width: 75px;
        height: 75px;
        background-color: red;
        border-radius: 50%;
        top:0;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .user-icon:hover .upload-logo{
        display: block;
    }
    .upload-logo{
      display: none;
      width: 80px;
      height: 80px;
      border-radius: 100%;
      /* left: 15px;
      top: 41px; */
      position: absolute;
      animation-name: showUpload ;
      animation-duration: .1s;
      background-color: #0000008a;
      font-size: 9px;
      align-items: center;
      text-align: center;
      padding: 10px;
    }
    .upload-logo > img{
      width: 20px;
      margin: 5px 0px;
    }
    @keyframes showUpload {
        0%{
            width: 25px;
            height: 25px;
            font-size: 0px;
            /* left: 20px;
            top: 45px; */
        }
        100%{
            width: 75px;
            height: 75px;
            font-size: 7px;
            /* left: 15px;
            top: 41px; */
        }
    }

@media screen and (max-width: 450px) {
  .profile-card-wrapper {
    padding: 0rem;
  }
  .profile-card-wrapper .profile-card {
    padding: 3rem 0rem;
  }
  .horizontal-line {
    margin: 2rem 5rem;
  }
}
@media screen and (max-width: 768px){
    .about-section{
        margin: 3rem 1rem;
    }
}
</style>
